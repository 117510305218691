import {
  defaultLocales,
  environmentVariables,
  subdomainLocales,
} from '~/assets/data/config';

interface IEnvironment {
  host?: string;
  enviroment?: {
    testHost?: boolean;
    localhost?: boolean;
    localeSubdomainHost?: null | string[];
    isLatestBuild: boolean;
  };
  storageConfigs?: {
    debugRegionCode?: string;
    debugEnvironment?: string;
    localeCookie?: string;
  };
  regionConigs?: {
    countryCode?: string;
    presetLocale?: string;
    runtimeEnvironment: string;
  };
}

export default function useEnvironment(): IEnvironment {
  const { $storageService } = useServices();
  const runtimeConfig = useRuntimeConfig();
  const siteStore = useSiteStore();
  const route = useRoute();

  const host = useRequestURL().host.split(':')[0];

  // url tests
  const testHost = computed(() => !!host.match(/uat|dev|qa/));
  const localhost = computed(() => !!host.match(/localhost/));
  const localeSubdomainHost = computed(() => host.match(/en|pt|sw/));

  // storage
  const debugRegionCode = $storageService.getUniversalStorage({
    key: 'debug-region',
  });
  const debugEnvironment = $storageService.getUniversalStorage({
    key: 'debug-environment',
  });
  const localeCookie = $storageService.getCookie({ key: 'locale' });

  const currentBuildTimeStamp = $storageService.getUniversalStorage({
    key: 'buildTimeStamp',
  });

  // configurations
  const latestBuildTimestamp = runtimeConfig?.public.buildTimestamp;
  const isLatestBuild = +currentBuildTimeStamp !== +latestBuildTimestamp;

  if (!isLatestBuild) $storageService.invalidateFullCache();
  $storageService.setUniversalStorage({
    key: 'buildTimeStamp',
    data: runtimeConfig?.public.buildTimestamp,
  });

  if (!!debugEnvironment) {
    siteStore.setEnvironment(debugEnvironment);
    Object.assign(
      runtimeConfig?.public,
      environmentVariables[debugEnvironment]
    );
  }

  const countryCode = localhost.value
    ? debugRegionCode || runtimeConfig?.public.defaultCountry
    : host?.slice(-2);

  const presetLocale =
    testHost.value && !!route.query?.locale
      ? String(route.query?.locale)
      : !!localeSubdomainHost.value
      ? subdomainLocales[localeSubdomainHost.value[0]]
      : !!localeCookie && (testHost.value || localhost.value)
      ? localeCookie
      : defaultLocales[`J${countryCode.toUpperCase()}`];

  $storageService.setUniversalStorage({
    key: 'environment',
    data: runtimeConfig?.public.environment,
  });

  return {
    host,
    enviroment: {
      testHost: testHost.value,
      localhost: localhost.value,
      localeSubdomainHost: localeSubdomainHost.value,
      isLatestBuild,
    },
    storageConfigs: { debugRegionCode, debugEnvironment, localeCookie },
    regionConigs: {
      countryCode,
      presetLocale,
      runtimeEnvironment: runtimeConfig?.public.environment,
    },
  };
}
