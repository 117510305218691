<script setup lang="ts">
// destructured imports
import { useSiteStore } from '~/stores/site.store';
import { useAuthStore } from '~/stores/auth.store';
import {
  unlockBodyScroll,
  boundaryErrorReporter,
} from '~/utilities/ui-utilities';
// components
import useModalContext from '~/composables/useModalContext';
import NavigationBar from '~/components/user-interface/navigation-bar.vue';
import OfflineNotice from '~/components/user-interface/offline-notice.vue';
import { useStorageService } from '~/composables/services/useStorageService';
import { useToastService } from '~/composables/services/useToastService';
// route
const router = useRouter();
const route = useRoute();
// stores
const settings = useSiteStore();
const auth = useAuthStore();
// destructured composables
const $storageService = useStorageService();
const $toastService = useToastService();
const { $t, $enabled } = useNuxtApp();
const { getAbsoluteUrl } = useAbsoluteUrl();
// composables
const runtimeConfig = useRuntimeConfig();
const viewport = useViewport();
const modalContext = useModalContext;

// refs
const overlay = ref(false);
const offline = ref<boolean>(false);
const localeCookie = $storageService.getCookie({ key: 'locale' });

// hooks
onMounted(async () => {
  if (!import.meta.env.SSR) {
    unlockBodyScroll();

    if (navigator.userAgent.indexOf('Opera Mini') !== -1) {
      await router.push('/browser-error.html');
    }
    addEventListener('offline', () => {
      if (!!route.query.id) {
        settings.activateModal('offline');
      } else {
        offline.value = true;
      }
    });
    addEventListener('online', () => {
      offline.value = false;
      settings.deactivateModal();
      settings.toggleSiteNotice({
        heading: $t('connection-restored-heading'),
        message: $t('connection-restored-message'),
        severity: 'success',
        enabled: true,
        timeout: 5000,
      });
    });
  }
});
// methods
function toggleOverlay() {
  overlay.value = !overlay.value;
}
const inGameMobile = computed(() => {
  return !viewport.isGreaterThan('tablet') && !!route.params?.game;
});
const modalContent = computed(() => modalContext(settings.currentModal));

function handleModalClosed() {
  if (
    !!route?.params?.game &&
    !route?.query?.freePlay &&
    (settings.currentModal === 'login' || settings.currentModal === 'register')
  )
    router.push('/');
  settings.deactivateModal();
}

const enableNotificationPanel = computed(
  () => auth.loggedIn && !!window && !!window?.indexedDB,
);

watchEffect(() =>
  useHead({
    meta: [
      {
        name: 'Jackpot City',
        content: `app-id=6476136241, app-argument=${document?.location?.host}`,
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: getAbsoluteUrl(route.path),
      },
    ],
    htmlAttrs: {
      lang: localeCookie ? localeCookie : 'en-za',
      translate: 'no',
    },
  }),
);
</script>
<template>
  <div>
    <svg
      style="width: 0; height: 0; position: absolute"
      aria-hidden="true"
      focusable="false"
    >
      <linearGradient id="bg-primary-pink-gradient" x2="1" y2="1">
        <stop offset="0%" stop-color="#d839ff" />
        <stop offset="100%" stop-color="#771f8c" />
      </linearGradient>
      <linearGradient id="bg-primary-blue-gradient" x2="1" y2="1">
        <stop offset="0%" stop-color="#0068ff" />
        <stop offset="100%" stop-color="#004ab5" />
      </linearGradient>
    </svg>
    <div
      class="default-layout relative w-full"
      :class="overlay ? 'p-overflow-hidden max-h-screen' : 'min-h-screen'"
    >
      <NuxtErrorBoundary
        @error="
          (err) =>
            boundaryErrorReporter({
              file: 'default.vue',
              componentBoundary: 'LiveChat',
              err,
            })
        "
      >
        <MarketingLiveChat
          :key="String(auth.isLoggedIn)"
          @toggle-live-chat="
            ($event) =>
              settings.explicitToggleFeature({
                feature: 'fullscreenLoading',
                toggle: $event,
              })
          "
        />
      </NuxtErrorBoundary>

      <LazyDesignSystemModal
        :title="
          $t(
            `${modalContent.titleKey}-${settings.getRegionCode}`,
            modalContent.titleKey,
          )
        "
        :active="settings.getModalActive"
        :close-modal-option="modalContent?.closeModalOption"
        :header-icon="modalContent?.headerIcon"
        :header-icon-class="modalContent?.headerIconClass"
        :icon-tooltip="modalContent?.iconTooltip"
        :width="modalContent?.width"
        @close-modal="handleModalClosed()"
      >
        <template #modal-content>
          <component
            :is="modalContent.component()"
            :key="modalContent.key"
            v-bind="modalContent"
            @close-modal="handleModalClosed()"
          />
        </template>
      </LazyDesignSystemModal>
      <LazyUserAccountModal
        :active="auth.accountModal"
        @close-modal="auth.toggleAccountModal(false)"
      />
      <LazyUserInterfaceToast
        v-if="
          $toastService.toastService.toasts.length &&
          $enabled('website.toastmessages') &&
          !route?.query?.id
        "
        :key="route.path"
        :toast="$toastService.toastService.nextToast"
      />

      <LazyDesignSystemToastMessages
        :type="settings.getSiteNotice.severity"
        :heading="settings.getSiteNotice.heading"
        :message="settings.getSiteNotice.message"
        :position="settings.getSiteNotice?.position"
        :timeout="settings.getSiteNotice.timeout"
        :enabled="settings.getSiteNotice.enabled"
      />
      <NuxtErrorBoundary
        @error="
          (err) =>
            boundaryErrorReporter({
              file: 'default.vue',
              componentBoundary: 'NavigationBar',
              err,
            })
        "
      >
        <NavigationBar v-show="!inGameMobile" @overlay="toggleOverlay()" />
      </NuxtErrorBoundary>
      <div v-show="overlay" class="jpc-overlay"></div>
      <OfflineNotice v-show="offline" class="body-wrap pb-6" />
      <main
        v-show="!offline"
        :key="String(inGameMobile)"
        class="body-wrap pb-6 bg-base min-h-[50vh]"
      >
        <slot />
        <LazyUserInterfaceCookieBar v-if="$enabled('website.cookiebar')" />
      </main>

      <LazyUserInterfaceSiteFooter v-show="!route?.query?.id" />
      <LazyUserInterfaceMobileFooter v-show="!inGameMobile" />
      <LazyMarketingNotificationsV1
        v-if="
          enableNotificationPanel &&
          String(runtimeConfig.public.newNotificationPanel) !== 'true'
        "
      />
      <LazyMarketingNotificationsV2
        v-else-if="
          enableNotificationPanel &&
          String(runtimeConfig.public.newNotificationPanel) === 'true'
        "
      />

      <LazyToolsDebugToolbar v-if="runtimeConfig?.public.enableDebugTools" />
    </div>
  </div>
</template>
