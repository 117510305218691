<script setup lang="ts">
import Button from '../design-system/button.vue';
const emits = defineEmits(['free-play', 'real-play']);

defineProps<{
  hasFreeplay: boolean;
}>();
</script>

<template>
  <div class="absolute h-full w-full">
    <div
      class="launch absolute h-full w-full flex-center flex-col z-1 text-base-priority gap-2"
    >
      <Button
        element-name="real-play"
        padding="wide"
        @click="emits('real-play')"
        class="capitalize min-w-[50%] max-w-[75%] justify-center text-center"
      >
        {{ $t(hasFreeplay ? 'real-play' : 'play') }}
      </Button>
      <Button
        element-name="free-play"
        padding="wide"
        type="secondary"
        @click="emits('free-play')"
        class="capitalize min-w-[50%] max-w-[75%] justify-center text-center"
        v-if="hasFreeplay || false"
      >
        {{ $t('free-play') }}
      </Button>
    </div>
  </div>
</template>
<style scoped>
.dark .launch {
  background-color: #1d2129c9;
}
.launch {
  background-color: #ffffffc9;
}
</style>
