export enum Account {
  MY_ACCOUNT_BONUS_WALLET = 'MyAccountBonusWalletFocusEvent',
  MY_ACCOUNT_BONUS_WALLET_HOW_TO = 'MyAccountBonusWalletHowToFocusEvent',
  MY_ACCOUNT_BONUS_WALLET_REFRESH = 'MyAccountBonusWalletRefreshFocusEvent',
  MY_ACCOUNT_BONUS_WALLET_FORFEIT = 'MyAccountBonusWalletForfeitFocusEvent',
  MY_ACCOUNT_BONUS_SUMMARY_INIT = 'MyAccountBonusSummaryInit',
  MY_ACCOUNT_BONUS_SUMMARY_COMPLETED = 'MyAccountBonusSummaryCompleted',
  MY_ACCOUNT_BONUS_SUMMARY_FAILURE = 'MyAccountBonusSummaryFailure',
  MY_ACCOUNT_BONUS_SUMMARY_SEARCH = 'MyAccountBonusSummarySearchFocusEvent',
  MY_ACCOUNT_BONUS_SUMMARY_DETAILS = 'MyAccountBonusSummaryDetailsFocusEvent',
  MY_ACCOUNT_DETAILED_TRANSACTION = 'MyAccountDetailedTransactionFocusEvent',
  MY_ACCOUNT_HISTORY_TRANSACTION_REFRESH = 'MyAccountHistoryTransacitonRefreshFocusEvent',
  MY_ACCOUNT_TELLER = 'MyAccountTellerFocusEvent',
  MY_ACCOUNT_HISTORY = 'MyAccountHistoryFocusEvent',
  MY_ACCOUNT_SETTINGS = 'MyAccountSettingsFocusEvent',
  MY_ACCOUNT_LOGOUT = 'LogOutInitiatedEvent',
  DOC_VERIFICATION_SUBMIT = 'DocVerificationItemSubmittedEvent',
  DOC_VERIFICATION_ERROR = 'DocVerificationErrorEvent',
  MY_ACCOUNT_DETAILS_UPDATE_ACTION = 'MyAccountDetailsActionEvent',
  MY_ACCOUNT_PASSWORD_UPDATE_ACTION = 'MyAccountPasswordActionEvent',
  MY_ACCOUNT_SETTINGS_UPDATE_ACTION = 'MyAccountSettingsActionEvent',
}
