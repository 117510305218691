import payload_plugin_qLmFnukI99 from "/home/vsts/work/1/s/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import unhead_KgADcZ0jPj from "/home/vsts/work/1/s/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/vsts/work/1/s/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_OVoKJro5pc from "/home/vsts/work/1/s/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_client_4F1kZh3YAB from "/home/vsts/work/1/s/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import primevue_plugin_egKpok8Auk from "/home/vsts/work/1/s/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/home/vsts/work/1/s/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import banner_observer_client_l5nrKEyGdr from "/home/vsts/work/1/s/node_modules/@osiris/banner-widget-nuxt/plugins/banner.observer.client.ts";
import _01_init_DR4PsC62lC from "/home/vsts/work/1/s/plugins/01.init.ts";
import _02_banner_tag_service_FBXYXC6ZNK from "/home/vsts/work/1/s/plugins/02.banner-tag.service.ts";
import analytics_client_mtqVw3YRXk from "/home/vsts/work/1/s/plugins/analytics.client.ts";
import directives_8CcCirWtnE from "/home/vsts/work/1/s/plugins/directives.ts";
import global_middleware_QwjltJ6fek from "/home/vsts/work/1/s/plugins/global.middleware.ts";
import global_observer_kp9OJLl1ZH from "/home/vsts/work/1/s/plugins/global.observer.ts";
import locale_service_jVfQb2yvjM from "/home/vsts/work/1/s/plugins/locale.service.ts";
import service_worker_message_handler_client_sqNcuVFacM from "/home/vsts/work/1/s/plugins/service-worker-message.handler.client.js";
import vueform_aUfEaQICb3 from "/home/vsts/work/1/s/plugins/vueform.ts";
import marketing_optin_service_client_F9cuZzQO4V from "/home/vsts/work/1/s/plugins/marketing-optin.service.client.ts";
export default [
  payload_plugin_qLmFnukI99,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_OVoKJro5pc,
  plugin_client_4F1kZh3YAB,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  banner_observer_client_l5nrKEyGdr,
  _01_init_DR4PsC62lC,
  _02_banner_tag_service_FBXYXC6ZNK,
  analytics_client_mtqVw3YRXk,
  directives_8CcCirWtnE,
  global_middleware_QwjltJ6fek,
  global_observer_kp9OJLl1ZH,
  locale_service_jVfQb2yvjM,
  service_worker_message_handler_client_sqNcuVFacM,
  vueform_aUfEaQICb3,
  marketing_optin_service_client_F9cuZzQO4V
]