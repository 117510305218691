<script setup lang="ts">
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
} from '@heroicons/vue/24/outline';
import Button from '../../components/design-system/button.vue';
import HamburgerMenu from '../../components/user-interface/hamburger-menu.vue';
import Logo from '~/components/design-system/logo.vue';
import { useSiteStore } from '~/stores/site.store';
import { useAuthStore } from '~/stores/auth.store';
import { useIntervalFn } from '@vueuse/core';
import RegionDebugDisplay from '~/components/tools/region-debug-display.vue';
import NavigationBarSecondaryNavigation from '~/components/user-interface/navigation-bar-secondary-navigation.vue';
import { remapSitemap } from '~/utilities/sitemap-mapper';
import useWelcomeOfferWheel from '~/composables/useWelcomeOfferWheel';

interface INavBarProps {
  inGame?: boolean;
}

const props = withDefaults(defineProps<INavBarProps>(), {
  inGame: false,
});
const { $enabled } = useNuxtApp();
const { $storageService } = useServices();

const settings = useSiteStore();
const auth = useAuthStore();
const route = useRoute();
const router = useRouter();
const emits = defineEmits(['overlay']);

const toggle = () => {
  dropDownActive.value = !dropDownActive.value;
  emits('overlay', dropDownActive.value);
};
const target = ref();

const dropDownActive: globalThis.Ref<boolean> = ref(false);

const listenToBackButton = () => {
  window.addEventListener('popstate', function () {
    if (route.fullPath.includes('aviator')) {
      router.push('/');
    } else {
      window.location.reload();
    }
  });
};
const { spinCount, spinCountLink } = useWelcomeOfferWheel();

onMounted(() => {
  listenToBackButton();
});

const navigationLinks = computed(() => {
  if (route.path.startsWith('/big-city-life'))
    return remapSitemap(settings.getSitemapMap.get('/big-city-life')?.Children);
  if (route.path.startsWith('/blog'))
    return remapSitemap(settings.getSitemapMap.get('/blog')?.Children);
  return settings.getNav({ nav: 'top' });
});

const activeLink = computed(() => (item) => {
  if (item?.MobileApp?.component === 'HomeComponent') {
    return route.path === '/';
  } else {
    return route.fullPath.startsWith(item.Path);
  }
});
</script>

<template>
  <div
    class="sticky top-0 bg-layer-2 dark:bg-layer-1 z-10 w-full shadow-lg dark:shadow-2xl"
  >
    <div
      class="container mx-auto flex justify-between items-center !py-1.5 px-2"
    >
      <div class="flex items-center max-w-fit overflow-hidden">
        <UserInterfaceHamburgerMenu v-show="!inGame" />
        <Logo
          element-name="platform-logo"
          image-class="cursor-pointer py-2 site-logo"
          @click="router.push('/')"
        />
        
        <NavigationBarSecondaryNavigation
          v-if="$enabled('website.enablesecondarynavigation')"
          class="mx-2"
        />
      </div>
      <NuxtErrorBoundary>
        <UserActionsNavBar class="user-actions--wrap" />
      </NuxtErrorBoundary>
    </div>
    <div
      v-show="!inGame"
      class="border-t-[1px] border-light-200 dark:border-dark-900 relative flex verticals-wrap md:py-1"
      :class="dropDownActive ? 'dropdown-wrap' : 'items-center'"
    >
      <div
        class="md:hidden border-r-[1px] std-border-color h-full z-2 p-2 text-base"
        @click="settings.activateModal('search')"
      >
        <MagnifyingGlassIcon class="w-5" />
      </div>
      <nav
        ref="target"
        class="container overflow-x-auto overflow-y-hidden nav relative no-scrollbar"
        :class="
          dropDownActive
            ? 'dropdown-active grid-cols-1 !px-0'
            : 'flex flex-row items-center relative gap-2 px-2'
        "
      >
        <NuxtLink
          v-for="(item, index) in navigationLinks"
          :key="item.id"
          :to="item.Path"
          no-prefetch
          :class="dropDownActive ? 'drop-down-wrapper w-full flex px-1' : ''"
          @click="dropDownActive ? toggle() : null"
        >
          <Button
            :element-name="`page-link-${item.Alias}`"
            class="capitalize text-sm my-1"
            :class="dropDownActive ? 'w-full' : ''"
            :rounding="dropDownActive ? 'md' : 'lg'"
            :padding="dropDownActive ? 'sm' : 'md'"
            :type="activeLink(item) ? 'primary' : 'transparent'"
          >
            {{ $t(`vertical.${item.Alias}`) }}
          </Button>
          <div
            v-if="dropDownActive && index === 0"
            class="md:hidden h-full z-2 p-2 border-l-[1px] std-border-color ml-1 text-base"
            @click="toggle()"
          >
            <ChevronDownIcon v-show="!dropDownActive" class="w-5" />
            <ChevronUpIcon v-show="dropDownActive" class="w-5" />
          </div>
        </NuxtLink>
        <UserInterfaceGenericSkeleton
          v-for="n in 6"
          v-show="settings.getNav({ nav: 'top' })?.length < 1"
          :key="n"
          width-class="w-[10%]"
          height-class="h-8"
        />
        <div class="relative">
          <Button
            v-if="+spinCount > 0 && auth.loggedIn && !!spinCountLink"
            class="hidden md:flex items-center text-sm"
            type="transparent"
            @click="router.push(spinCountLink)"
          >
            {{ $t('spin-now') }}
            <span
              class="badge absolute bg-blue-500 rounded-full top-[2px] right-[2px] text-sm text-white w-4 h-4 flex-center"
            >
              <span class="mx-auto text-center w-full font-bold">
                {{ spinCount }}
              </span>
            </span>
          </Button>
        </div>
      </nav>
      <div
        class="md:hidden h-full z-2 p-2 border-l-[1px] std-border-color text-base"
        @click="toggle()"
      >
        <ChevronDownIcon v-show="!dropDownActive" class="w-5" />
        <ChevronUpIcon v-show="dropDownActive" class="w-5" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.site-logo {
  @media (min-width: 1536px) {
    height: 56px;
  }
  @media (min-width: 786px) and (max-width: 1535px) {
    height: 48px;
  }
  @media (min-width: 360px) and (max-width: 785px) {
    height: 45px;
  }
  height: 40px;
}
.nav {
  transition: ease flex 150ms;
}

.verticals-wrap {
  & .drop-down-wrapper {
    border-bottom: 1px solid #dae0ed;
    width: 100%;
    z-index: 1;
  }
  .dark & .drop-down-wrapper {
    border-bottom: 1px solid #464f60;
  }
  & .search {
    border-right: solid 1px #dae0ed;
    border-radius: 0;
  }
  .dark & .search {
    border-right: solid 1px #464f60;
  }
  & .p-button.p-button-icon-only {
    height: 38px;
    width: 38px;
    padding: 0 8px;
  }
  & .toggle {
    height: 37px;
    box-shadow: -4px 0 4px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #dae0ed;
    border-radius: 0;
    width: 38px;
    position: absolute;
    z-index: 999999;
    right: 0;
    & svg {
      height: 15px !important;
      width: auto !important;
    }
    &.no-shadow {
      box-shadow: none;
    }
  }
  .dark & .toggle {
    box-shadow: -4px 0 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #464f60;
    &.no-shadow {
      box-shadow: none;
    }
  }
}

@media (max-width: 767px) {
  .nav {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    &.dropdown-active {
      position: absolute;
      height: unset;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      z-index: 10;
      .dark & {
        background: #1d2129;
      }
      width: calc(100vw - 38px);
      right: 0;
    }
    & .button--medium {
      height: 27px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .verticals-wrap {
    & nav {
      padding-right: 4.5em !important;
      &.dropdown-active {
        padding-right: 0 !important;
      }
    }
  }
}
</style>
