import { useIntervalFn } from '@vueuse/core';

export default function () {
  const customWelcomeOfferLinks = {
    JZA: '/welcome-offer',
    JMW: '/fikani-wheel',
  };

  const { $storageService } = useServices();

  const siteStore = useSiteStore();
  const authStore = useAuthStore();
  const spinCount = ref<number>(0);
  const spinCountLink = ref<number>(0);
  const { pause, resume, isActive } = useIntervalFn(() => {
    const welcomeSpinCount =
      $storageService.getLocalStorage({
        key: 'BigCityLifeWheel:SpinCount',
      }) || 0;

    spinCount.value = +welcomeSpinCount;
    spinCountLink.value =
      customWelcomeOfferLinks[siteStore.getRegionCode] || '/welcome-offer';
  }, 10000);

  watchEffect(() => {
    if (authStore.isLoggedIn) resume();
    else pause();
  });
  return { spinCount, spinCountLink };
}
