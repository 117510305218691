<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { unlockBodyScroll } from '~/utilities/ui-utilities';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import UserBalances from '~/components/user/user-balances.vue';

const emits = defineEmits<{
  (e: 'toggleMenu'): void;
}>();

const auth = useAuthStore();
const settings = useSiteStore();
</script>

<template>
  <div class="bg-primary-layer p-2 w-full">
    <div class="flex gap-1">
      <div v-if="!auth.isLoggedIn" class="flex gap-1">
        <Button
          element-name="hamburger-menu-login"
          type="secondary"
          @click="
            [
              settings.activateModal('login'),
              emits('toggleMenu'),
              unlockBodyScroll(),
            ]
          "
        >
          {{ $t('login') }}
        </Button>
        <Button
          element-name="hamburger-menu-register"
          @click="
            [
              settings.activateModal('register'),
              emits('toggleMenu'),
              unlockBodyScroll(),
            ]
          "
        >
          {{ $t('jpc-register') }}
        </Button>
      </div>
      <div v-else class="flex flex-wrap flex-1 text-xs">
        <div class="truncate">
          {{ $t('welcome') }}
          <!-- prettier-ignore -->
          <strong>
            <!-- If first and last name exist display else display fallback -->
            {{
              !!auth.currentUser?.firstname
                  ? auth.currentUser?.firstname
                  : $t('user')
            }}
          </strong>
        </div>
        <div class="truncate">
          {{ $t('acc-number') }}:
          <strong>
            {{ auth.currentUser?.primaryMobileNumber }}
          </strong>
        </div>
      </div>
      <UserInterfaceGenericThemeToggle v-if="auth.isLoggedIn" />
      <Button
        element-name="hamburger-menu-close"
        type="secondary"
        shape="square"
        class="ml-auto"
        @click="emits('toggleMenu')"
      >
        <XMarkIcon />
      </Button>
    </div>
    <UserBalances
      v-if="auth.isLoggedIn"
      class="mt-1 w-full"
      display-balance-toggle
      display-cash-balance
      display-bonus-balance
      display-deposit-button
      :display-truncated-balance="$enabled('website.truncatecurrency')"
    />
  </div>
</template>

<style scoped lang="scss"></style>
