import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { useServices } from '~/composables/useServices';
import { useCheckRestrictions } from '~/composables/useCheckRestrictions';

import ToastService from 'primevue/toastservice';
import DatePicker from 'primevue/datepicker';
import useEnvironment from '~/composables/useEnvironment';
export default defineNuxtPlugin({
  name: 'init-client',
  parallel: true,
  async setup(nuxt) {
    const enviroment = useEnvironment();
    const { $configService, $storageService } = useServices();
    await $storageService.invalidateStaleIndexDb();

    const siteStore = useSiteStore();
    const localeStore = useLocaleStore();

    nuxt.vueApp.use(ToastService);
    nuxt.vueApp.component('DatePicker', DatePicker);

    siteStore.setRegion(enviroment.regionConigs.countryCode);
    localeStore.setLocaleCode(enviroment.regionConigs.presetLocale);

    await $configService.fetchFullConfig(
      siteStore.regionCode,
      localeStore.getLocale,
    );

    useWindowFunctions();
  },
  hooks: {
    'page:finish': () => {
      const loading = document.getElementById('global_loading');
      if (loading) {
        loading.style.cssText = 'display:none !important;';
      }
    },
    async 'app:created'(nuxt) {
      const { $storageService } = useServices(nuxt.$nuxt);

      const route = useRoute();
      const currentBtag = $storageService.getCookie({ key: 'BTAGCOOKIE' });
      if (!currentBtag && !!route.query?.btag) {
        $storageService.setCookie({
          key: 'BTAGCOOKIE',
          data: route.query?.btag,
          expiry: 7,
        });
      }
      if (!!currentBtag && !!route.query?.btag) {
        $storageService.setCookie({
          key: 'REFERRERBTAGCOOKIE',
          data: route.query?.btag,
          expiry: 0,
        });
      }
    },
    'app:error'(err) {
      console.error({ type: 'app error', err });
    },
    'vue:error'(err, instance, info) {
      console.error({ type: 'vue error', err, instance, info });
    },
    async 'app:mounted'(nuxt) {
      const { $toastService, $gameService } = useServices();
      const siteStore = useSiteStore(nuxt.$nuxt.$pinia);
      const auth = useAuthStore(nuxt.$nuxt.$pinia);

      if (siteStore.getFeatureFlags.get('website.toastmessages')) {
        await $toastService.toastService.fetchToasts();
      }
      await $gameService.fetchBuildVersion({
        regionCode: siteStore.regionCode,
      });

      await auth.initAuth();
    },
  },
});
