<script setup lang="ts">
const siteStore = useSiteStore();
const router = useRouter();
const route = useRoute();
const navigation = computed(() => {
  return (
    siteStore.getSitemapMap.get('/secondary-navigation') || { Children: [] }
  );
});
const activeNavigation = computed(() => {
  return (index: number) => {
    switch (index) {
      case 0:
        return 'primary';
      case 1:
        return 'neutral';
      case 2:
        return 'primary-alternative';
    }
  };
});
</script>

<template>
  <div
    v-if="navigation?.Children.length > 0"
    class="bg-primary-layer-alternative p-1 rounded-2xl lg:flex gap-1 hidden"
  >
    <DesignSystemButton
      v-for="(child, index) in navigation?.Children"
      :key="index"
      :type="
        route.path === child?.RURL ? activeNavigation(+index) : 'transparent'
      "
      @click="router.push(child?.RURL)"
    >
      {{ child?.C }}
    </DesignSystemButton>
  </div>
</template>

<style scoped lang="scss"></style>
