<script setup lang="ts">
import { Bars3BottomLeftIcon } from '@heroicons/vue/24/outline';
import Button from '~/components/design-system/button.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { trackingMethods } from '~~/plugins/analytics.client';
import { lockBodyScroll, unlockBodyScroll } from '~/utilities/ui-utilities';
import { useAccountOptions } from '~/composables/useAccountOptions';
import { accordionTabPassthrough } from '~/components/passthroughs/accordion';
import HamburgerMenuHeader from '~/components/user-interface/hamurger-menu-header.vue';
import Select from 'primevue/select';
import Drawer from 'primevue/drawer';

import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';

import type { ILanguage } from '~/interfaces/dto/general/language';
const { $enabled } = useNuxtApp();

const { $walletService } = useServices();
const auth = useAuthStore();
const settings = useSiteStore();
const router = useRouter();
const accountNavBarMenu = computed(() =>
  useAccountOptions().filter((uao) => uao.includeInHamburger && uao.active)
);

let selectedLanguage = 'Select a language';
let languages: Array<ILanguage> = [];
let isDisabled = false;
const activeRoute = computed(() => router.currentRoute.value.path);

const refreshBalances = async () => {
  await $walletService.fetchBalances();
};

onMounted(() => {
  const cultures = !!settings.getConfig?.cultures
    ? settings.getConfig?.cultures
    : settings.getConfig?.Cultures;

  languages = cultures;

  if (cultures?.length == 1) {
    selectedLanguage = cultures[0]['Translation'];
    isDisabled = true;
  }
});

//prevent body scrolling whilst hamburger active
watch(
  () => settings.getHamburgerMenuActive,
  (hamburgNew, hamburgOld) => {
    hamburgNew ? lockBodyScroll() : unlockBodyScroll();
    if (hamburgNew) {
      trackingMethods.toggleHamburgerMenu();
      refreshBalances();
    }
  }
);

const enableMenuText = computed(
  () => settings.getFeatureFlags.get('website.enablemenutext') || false
);
</script>

<template>
  <div>
    <Button
      element-name="hamburger-menu-open"
      type="transparent"
      class="mr-1 xs:mr-2 md:mr-3 column-alignment"
      @click="settings.toggleHamburgerMenu()"
    >
      <div>
        <Bars3BottomLeftIcon />
      </div>
      <div v-if="enableMenuText">
        <p class="m-0 text-0.5xs font-bold text-base-priority lowercase">
          {{ $t('menu') }}
        </p>
      </div>
    </Button>
    <Drawer
      v-model:visible="settings.hamburgerMenuActive"
      :show-close-icon="false"
    >
      <template #header>
        <HamburgerMenuHeader @toggle-menu="settings.toggleHamburgerMenu()" />
      </template>

      <div class="">
        <Accordion
          :value="auth.isLoggedIn ? '0' : '1'"
          :multiple="false"
          class="hamburger-accordion"
          :pt="accordionTabPassthrough.accordion"
        >
          <AccordionPanel v-if="auth.isLoggedIn" value="0">
            <AccordionHeader :pt="accordionTabPassthrough.headerAction">
              {{ $t('my-account') }}
            </AccordionHeader>
            <AccordionContent :pt="accordionTabPassthrough.content">
              <div class="flex flex-col gap-2">
                <Button
                  v-for="item in accountNavBarMenu"
                  :key="item.id"
                  class="w-full"
                  :type="
                    activeRoute === `${item.Path}`
                      ? 'primary'
                      : 'tertiary-alternative'
                  "
                  @click="
                    [
                      auth.setAccountModalPage(item.id),
                      settings.toggleHamburgerMenu(),
                      unlockBodyScroll(),
                    ]
                  "
                >
                  {{ $t(item.key) }}
                </Button>
              </div>
            </AccordionContent>
          </AccordionPanel>

          <AccordionPanel value="1">
            <AccordionHeader :pt="accordionTabPassthrough.headerAction">
              {{ $t('quick-links') }}
            </AccordionHeader>
            <AccordionContent :pt="accordionTabPassthrough.content">
              <div class="flex flex-col gap-2">
                <Button
                  v-for="item in settings.getNav({ nav: 'left' })"
                  :key="item.Alias"
                  class="w-full"
                  :type="
                    activeRoute === `${item.Path}`
                      ? 'primary'
                      : 'tertiary-alternative'
                  "
                  @click="
                    [router.push(item.Path), settings.toggleHamburgerMenu()]
                  "
                >
                  {{ $t(item.Alias) }}
                </Button>
              </div>
            </AccordionContent>
          </AccordionPanel>
        </Accordion>
      </div>
      <div class="flex flex-col bg-primary-layer p-2 bottom-0 w-full">
        <UserInterfaceAppsDownload
          v-if="$enabled('website.enableappdownload')"
          direction="vertical"
        />

        <div v-if="languages?.length > 1" class="p-1 mt-2">
          <Select
            class="w-full"
            :options="languages"
            option-label="translation"
            :placeholder="selectedLanguage"
            :disabled="isDisabled"
            option-value="cultureCode"
            :model-value="$locale.getLocale"
            @change="
              [
                $locale.changeLocale($event.value),
                settings.toggleHamburgerMenu(),
              ]
            "
          />
        </div>
      </div>
    </Drawer>
  </div>
</template>

<style lang="scss" scoped>
@media only screen and (min-device-height: 200px) and (max-device-height: 740px) and (orientation: portrait) {
  .sidebar-item-container {
    max-height: 22rem !important;
    display: grid !important;
    overflow-y: scroll !important;
  }
}

@media only screen and (min-device-height: 200px) and (max-device-height: 600px) and (orientation: landscape) {
  .sidebar-item-container {
    max-height: 19rem !important;
    display: grid !important;
    overflow-y: scroll !important;
  }
}
</style>

<style lang="scss">
.hamburger-accordion.p-accordion .p-accordion-content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.p-accordion-content .link-active {
  color: red !important;
}

.hamburger-accordion .p-accordion-header-link.p-accordion-header-action {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
}

.box-shadow-apps {
  box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  .dark & {
    background-color: #1d2129;
  }
}

.p-sidebar .p-sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #eceff6;
  .dark & {
    background-color: #121417;
  }
}

.p-sidebar .p-sidebar-header .p-sidebar-close {
  position: absolute;
  right: 18px;
  top: 16px;
  border-radius: 6px;
}

.p-sidebar-close-icon {
  background: #eceff6;
  color: #2b303b;
  height: 20px;
  width: 20px;
  border-radius: 6px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  background-color: #eceff6;
}

.p-sidebar .p-sidebar-header button.p-sidebar-close.p-sidebar-icon.p-link {
  box-shadow: none;
}

.right-arrow > a {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.dark {
  .p-sidebar-close-icon {
    background: #121417;
    color: #fff;
  }

  .hamburger-accordion .p-accordion-tab .p-accordion-content {
    background-color: #1d2129;
    border-color: #464f60;
    border-width: 0px;
  }

  .hamburger-accordion .p-accordion-header-link.p-accordion-header-action {
    box-shadow: 0 6px 12px 0 #000;
  }

  .p-sidebar .p-sidebar-header .p-sidebar-close,
  .p-sidebar .p-sidebar-header .p-sidebar-icon,
  button.p-sidebar-close.p-sidebar-icon.p-link:hover {
    background: #121417;
  }
}

.column-alignment {
  display: flex;
  flex-direction: column;
}
</style>
